<template>
  <input
    invisible-file-input
    type="file"
    :multiple="multiple"
  >
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    $fileBrowser: null,
    files: [],
    loading: false,
  }),
  mounted() {
    this.initFileBrowser()
  },
  methods: {
    async initFileBrowser() {
      await this.$nextTick()
      this.$fileBrowser = $(this.$el)
      this.$fileBrowser.change(event => {
        this.handleFileChange(event.target.files)
      })
    },
    openFileBrowser() {
      if(this.loading) {
        this.resetFileBrowser()
        return
      }
      this.$fileBrowser.click()
    },
    resetFileBrowser() {
      this.$fileBrowser.val('')
    },
    async handleFileChange(files) {
      this.files = []
      if(files.length == 0) {
        this.resetFileBrowser()
        return
      }

      if(this.loading) return
      this.loading = true
      for(let index=0; index<=files.length-1; index++) {
        const file = files[index]
        await this.uploadFile(file)
      }
      this.loading = false
      this.resetFileBrowser()
      this.init()
    },
    async uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      try {
        let result = await this.uploadApi(this.type, formData, this.onProgress)
        result.url = `${this.fileBaseUrl}${result.path.substring(1)}`
        this.$emit('appendFile', result)
        this.$snotify.success(
          file.name,
          this.$t('file.upload.successfully')
        )
      } catch (error) {
        console.error(error)
        let message = null
        if(error.status == 406) message = this.$t(`error.storage.out_of_limit`)
        this.$snotify.error(
          message,
          this.$t('file.upload.failure'),
        )
      }
    },
    init() {
      this.$fileBrowser.val('')
      this.files = []
    },
  },
  computed: {
    fileBaseUrl() {
      return this.$store.getters['base/application'].fileBaseUrl
    },
    uploadApi() {
      return this.$api.collection.fileApi.upload
    },
  },
  watch: {
    loading() {
      this.$emit('onLoadingChange', this.loading)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
input[type="file"][invisible-file-input]
  width: 0
  height: 0
  opacity: 0
  position: absolute
  pointer-events: none
</style>
